// import { Button, Space } from "antd";
// import { useState } from "react";
import ChangePassword from "../../../components/ui/settings/ChangePassword";
import UserInfo from "../../../components/ui/settings/UserInfo";
// import Subheader from "../../../components/ui/Subheader";
import AdminLayout from "../../../layouts/AdminLayout";

// const normalBtn = "bg-transparent text-accent font-medium px-1";

export default function Settings() {
  // const [activeTab, setActiveTab] = useState<"INFO" | "PASSWORD">("INFO");

  return (
    <AdminLayout title="Settings">
      {/* <Subheader className="roboto_font" title="Settings">
        <Space size="small">
          <Button
            type="text"
            onClick={() => setActiveTab("INFO")}
            className={activeTab === "INFO" ? "secondary-btn" : normalBtn}
          >
            User Info
          </Button>
          <Button
            type="text"
            onClick={() => setActiveTab("PASSWORD")}
            className={activeTab === "PASSWORD" ? "secondary-btn" : normalBtn}
          >
            Change Password
          </Button>
        </Space>
      </Subheader> */}

      <div className="grid md:grid-cols-3 gap-6 pt-6">
        <UserInfo />
        <div className="md:col-span-2">
          {/* {activeTab === "INFO" ? <AccessLog /> : <ChangePassword />} */}
          <ChangePassword />
        </div>
      </div>
    </AdminLayout>
  );
}
