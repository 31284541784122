export const BASE_API = {
  // Auth -(Account Management)
  login: "/user/login",

  // Geo Info -(GEO Setup)
  createRegion: "/geo/create-region",
  updateRegion: "/geo/update-region-by",
  getAllRegion: "/geo/get-regions",
  getSingleRegion: "/geo/get-region-by",
  getCountRegion: "/geo/count-regions",

  createArea: "/geo/create-area",
  updateArea: "/geo/update-area-by",
  getAllArea: "/geo/get-areas",
  getSingleArea: "/geo/get-area-by",
  getCountArea: "/geo/counts-area",

  createTerritory: "/geo/create-teritory",
  updateTerritory: "/geo/update-teritory-by",
  getAllTerritory: "/geo/get-teritorys",
  getSingleTerritory: "/geo/get-teritory-by",
  getCountTerritory: "/geo/counts-teritory",

  // Resource -(User)
  checkEmail: "/user/check-email",
  checkPhone: "/user/check-phone",
  checkNID: "/user/check-nid",

  createUser: "/user/create-user",
  getAllUsers: "/user/get-users",
  getCountUsers: "/user/counts-user",

  // Network -(Tower Management)
  checkTowerName: "/tower/check-tower-name",
  checkSiteCode: "/tower/check-site-code",

  createTower: "/tower/create-tower",
  getAllTower: "/tower/get-towers",
  getCountTower: "/tower/counts-tower",

  createTowerTicket: "/tower/create-ticket",
  towerTicketNotification: "/tower/send-notification-to-related-pg",
  sendFeedback: "/tower/admin-give-feedback",
  networkLogView: "/tower/get-single-tower-log",

  // Supplier
  createSupplier: "/user/create-supplier",
  getAllSupplier: "/user/get-suppliers",
  getCountSupplier: "/user/counts-suppliers",
  getSupplierUser: "/user/get-user-by-supplier",
  getCountUserSupplier: "/user/get-number-of-user-by-supplier",

  // Dashboard
  getActiveAndInactiveTower: "/tower/get-active-inactive-numer-of-tower",
  getActiveAndInactiveMap: "/tower/get-active-inactvie-route-for-map",

  /**** RENTAL ****/
  // bank
  createBank: "/proprietor/bank/create",
  getAllBank: "/proprietor/bank/get-all",

  // branch
  createBranch: "/proprietor/branch/create",
  getAllBranch: "/proprietor/branch/get-all",

  // proprietors
  createProprietor: "/proprietor/create-propritor",
  getAllProprietor: "/proprietor/get-all-propritor",
  getSingleProprietor: "/proprietor/get-propritor",
  getProprietorPaymentHistory: "/proprietor/get-propritor-payment-history",
  updateProprietorDocument: "/proprietor/material-update",

  // payment
  createPayment: "/proprietor/payment/create",
  getAllPayment: "/proprietor/payment/get-data",
  getSinglePayment: "/proprietor/payment/get-data",

  // Notification
  createNotification: "/proprietor/create-propritor-notification",
  getAllNotification: "/proprietor/proprietor-get-notification-web",

  // dashboard
  getDuePayment: "/proprietor/rental/dashboard-due-payment",
  getSiteData: "/proprietor/rental/dashboard-total-site-data",
  getPayableNumber: "/proprietor/payable-number",
  getPayableUser: "/proprietor/payable-user",

  // Image -(File Upload)
  fileUpload: `/tower/upload-image`,
};
