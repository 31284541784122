import { Form, FormProps, Input } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import DocumentIcon from "../../../../assets/icons/DocumentIcon";
import CustomForm from "../../../../components/common/CustomForm";
import AdminLayout from "../../../../layouts/AdminLayout";
import { createBankAsync } from "../../../../store/features/admin/bank/bankAPI";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { BankData } from "../../../../types/redux";

const navLink = "/admin/bank-manage";

export default function ManageBank() {
  const { id, viewId } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { postLoading } = useAppSelector((state) => state.bank);

  const onFinish: FormProps<BankData>["onFinish"] = (values) => {
    if (id) {
      // dispatch(
      //   updateAreaAsync({
      //     data: values,
      //     others: {
      //       reRender,
      //       id,
      //     },
      //   })
      // );
    } else {
      dispatch(
        createBankAsync({
          data: values,
          others: {
            reRender,
          },
        })
      );
    }
  };

  // redirect and re-render function
  const reRender = (val: boolean) => {
    if (val) navigate(navLink);
  };

  //   useEffect(() => {
  //     if ((id || viewId) && singleArea && updateLoading === false) {
  //       form.setFieldsValue({
  //         region: singleArea?.region?._id,
  //         areaName: singleArea?.areaName,
  //       });
  //     }
  //   }, [form, id, singleArea, updateLoading, viewId]);

  //   useEffect(() => {
  //     if (id || viewId) {
  //       dispatch(getSingleRegionAsync({ params: { id: id || viewId || "" } }));
  //     }
  //   }, [dispatch, id, viewId]);

  const title = id ? "Update Bank" : viewId ? "View Bank" : "Add New Bank";

  return (
    <AdminLayout title={title}>
      {/* {viewLoading ? <Loader /> : null} */}
      <CustomForm
        onFinish={onFinish}
        title={title}
        backLink={navLink}
        disabled={postLoading}
        // disabled={postLoading || updateLoading}
        isUpdate={id ? true : false}
        hideBtn={viewId ? true : false}
        // disabledSubmit={viewLoading}
        maxWidth={600}
        formProps={{
          form,
        }}
        formTitle={
          <span className="text-primary flex items-center gap-2">
            <span className="-ml-1 mt-1.5">
              <DocumentIcon />
            </span>
            <span className="text-lg font-medium font-roboto leading-[30px] mt-1">
              Bank Information
            </span>
          </span>
        }
      >
        <Form.Item
          name="name"
          label="Bank name"
          rules={[
            {
              required: true,
              message: "Please enter bank name",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="Enter bank name"
            disabled={viewId ? true : false}
          />
        </Form.Item>
      </CustomForm>
    </AdminLayout>
  );
}
