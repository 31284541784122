import { createSlice } from "@reduxjs/toolkit";
import {
  GetProprietorData,
  ProprietorsInitialStatus,
} from "../../../../types/redux/admin/proprietors";
import {
  createProprietorAsync,
  getAllProprietorsAsync,
  getProprietorPaymentHistoryAsync,
  getSingleProprietorsAsync,
  updateProprietorDocumentAsync,
} from "./proprietorsAPI";

const initialState: ProprietorsInitialStatus<GetProprietorData> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  viewLoading: false,
  allProprietors: [],
  singleProprietor: null,
  proprietorCount: 0,
  paymentHistory: [],
};

const proprietorSlice = createSlice({
  name: "admin-proprietors",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create proprietor
    builder
      .addCase(createProprietorAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createProprietorAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createProprietorAsync.rejected, (state) => {
        state.postLoading = false;
      });
    builder
      .addCase(updateProprietorDocumentAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateProprietorDocumentAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateProprietorDocumentAsync.rejected, (state) => {
        state.updateLoading = false;
      });
    // get all proprietors
    builder
      .addCase(getAllProprietorsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProprietorsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.allProprietors = action.payload;
      })
      .addCase(getAllProprietorsAsync.rejected, (state) => {
        state.loading = false;
        state.allProprietors = [];
      });
    // get single proprietors
    builder
      .addCase(getSingleProprietorsAsync.pending, (state) => {
        state.viewLoading = true;
      })
      .addCase(getSingleProprietorsAsync.fulfilled, (state, action) => {
        state.viewLoading = false;
        state.singleProprietor = action.payload;
      })
      .addCase(getSingleProprietorsAsync.rejected, (state) => {
        state.viewLoading = false;
        state.singleProprietor = null;
      });
    // get payment history
    builder
      .addCase(getProprietorPaymentHistoryAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProprietorPaymentHistoryAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentHistory = action.payload;
      })
      .addCase(getProprietorPaymentHistoryAsync.rejected, (state) => {
        state.loading = false;
        state.paymentHistory = [];
      });
  },
});

export default proprietorSlice;
