import { Button, Select, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddIcon from "../../../assets/icons/AddIcon";
import SelectDownIcon from "../../../assets/icons/SelectDownIcon";
import CustomTable from "../../../components/common/CustomTable";
import IconButton from "../../../components/common/IconButton";
import Subheader from "../../../components/ui/Subheader";
import {
  addKeyInArray,
  ArrayOption,
  selectSearchOption,
} from "../../../helpers/siteConfig";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import { getAllProprietorsAsync } from "../../../store/features/admin/proprietors/proprietorsAPI";
import {
  getAllAreaAsync,
  getAllRegionAsync,
} from "../../../store/features/NP/geoInfo/geoAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { TableData } from "../../../types/redux";
import { countRow } from "../../../utils/number";
import { capitalize } from "../../../utils/string";
import {
  findFiscalYearByValue,
  formatDate,
  isExpired,
  isOneYearExpired,
} from "../../../utils/time";

export default function ProprietorsManage() {
  const dispatch = useAppDispatch();
  // const [selectedData, setSelectedData] = useState<React.Key[]>([]);
  const [region, setRegion] = useState<undefined | string>(undefined);
  const [area, setArea] = useState<undefined | string>(undefined);
  // const [tax, setTax] = useState<undefined | string>(undefined);
  // const [agreement, setAgreement] = useState<undefined | string>(undefined);
  const {
    limit,
    page,
    handlePagination,
    handleLimit,
    handleSetFirstPage,
    isFirstPage,
  } = useTable(1, 10);
  const { regions, areas } = useAppSelector((state) => state.npGeo);
  // const { keyword, handleInputChange } = useDebounce(1000);
  const { allProprietors, loading } = useAppSelector(
    (state) => state.proprietor
  );
  const allProprietorsData = allProprietors?.slice(
    (page - 1) * limit,
    limit * page
  );

  const columns: ColumnsType<TableData<any>> = [
    {
      title: "#",
      align: "center",
      width: 60,
      render: (_, __, idx) => countRow(page, limit, idx),
    },
    {
      title: "Proprietor’s name",
      dataIndex: "fullName",
      render: (val) => (
        <span className="text-header text-base font-normal">{val}</span>
      ),
    },
    {
      title: "Site name & Code",
      render: (val) => (
        <div>
          <span className="font-medium font-roboto">
            {capitalize(val?.towerId?.towerName || "")}
          </span>
          <br />
          <small className="text-xs font-normal leading-tight tracking-tight text-accent">
            {val?.towerId?.siteCode
              ? `Site Code: ${val?.towerId?.siteCode}`
              : ""}
          </small>
        </div>
      ),
    },
    {
      title: "Contact No.",
      dataIndex: "activeMobileNumber",
      render: (val) => (
        <span className="!text-accent text-base font-normal">{val}</span>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      render: (val) => (
        <span className="text-accent text-base font-normal">{val}</span>
      ),
    },
    {
      title: "Region",
      dataIndex: "regionName",
      render: (val) => (
        <span className="text-header text-base font-normal">{val}</span>
      ),
    },
    {
      title: "Tax Expire",
      dataIndex: "document",
      render: (val) => (
        <span
          className={`${
            isOneYearExpired(val?.taxCertificateExpiryDate)
              ? "text-status-danger-text"
              : ""
          }`}
        >
          {val?.taxCertificateExpiryDate
            ? findFiscalYearByValue(
                formatDate(val?.taxCertificateExpiryDate, "DD/MM/YYYY")
              )
            : "N/A"}
        </span>
      ),
    },
    {
      title: () => (
        <>
          Agreement <br /> Expire
        </>
      ),
      dataIndex: "document",
      render: (val) => (
        <span
          className={`${
            isExpired(val?.aggrementPaperExpireDate)
              ? "text-status-danger-text"
              : ""
          }`}
        >
          {formatDate(val?.aggrementPaperExpireDate)}
        </span>
      ),
    },
    {
      title: "Action",
      align: "center",
      render: (val) => (
        <Link to={`/admin/proprietors-manage/${val?._id}`}>
          <Button type="primary">Open</Button>
        </Link>
      ),
    },
  ];

  useEffect(() => {
    const params = {
      limit: limit,
      skip: limit * (page - 1),
      region,
      area,
    };

    // console.log(keyword);

    dispatch(getAllProprietorsAsync({ params }));
  }, [area, dispatch, limit, page, region]);

  useEffect(() => {
    dispatch(getAllRegionAsync({}));
  }, [dispatch]);
  useEffect(() => {
    if (region) {
      dispatch(getAllAreaAsync({ params: { region } }));
    }
  }, [dispatch, region]);

  useEffect(() => {
    //  || agreement || keyword || tax
    if ((region || area) && isFirstPage) {
      handlePagination(1, 10);
      handleSetFirstPage(false);
    }
  }, [area, handlePagination, handleSetFirstPage, isFirstPage, region]);

  return (
    <AdminLayout title="Proprietor’s Management List">
      <Subheader title="Proprietor’s Management List">
        <Space size="middle">
          <Link to="/admin/proprietors-manage/create">
            <IconButton type="text" className="primary-btn" icon={<AddIcon />}>
              Add New
            </IconButton>
          </Link>
        </Space>
      </Subheader>

      <CustomTable<TableData<any>>
        loading={loading}
        total={allProprietors?.length}
        limit={limit}
        page={page}
        handlePagination={handlePagination}
        columns={columns}
        dataList={addKeyInArray(allProprietorsData)}
        isLimitRow={true}
        showTotalPage={true}
        handleLimit={handleLimit}
        tableClass="bill_generate_table"
        // rowSelection={{
        //   type: "checkbox",
        //   onChange: (key) => setSelectedData(key),
        //   columnWidth: 45,
        // }}
        tableTopChildren={
          <Space className="flex-wrap justify-end filter_area" size={"small"}>
            {/* <Input
              suffix={<SearchIcon />}
              placeholder="Search by Name, Code, Tower"
              className="min-w-[18rem]"
              onChange={(val) => {
                handleInputChange(val);
                handleSetFirstPage(true);
              }}
            /> */}
            <Select
              allowClear
              showSearch
              value={region}
              placeholder="Region"
              filterOption={selectSearchOption}
              options={ArrayOption(regions, "regionName", "_id")}
              onChange={(val) => {
                setRegion(val);
                setArea(undefined);
                handleSetFirstPage(true);
              }}
              className="min-w-[7rem]"
              suffixIcon={<SelectDownIcon width={14} height={8} />}
            />
            <Select
              allowClear
              showSearch
              value={area}
              placeholder={"Area"}
              filterOption={selectSearchOption}
              options={ArrayOption(areas, "areaName", "_id")}
              onChange={(val) => {
                setArea(val);
                handleSetFirstPage(true);
              }}
              disabled={!region}
              className="min-w-[7rem]"
              suffixIcon={<SelectDownIcon width={14} height={8} />}
            />
            {/* <Select
              allowClear
              showSearch
              filterOption={selectSearchOption}
              placeholder={"All Tax"}
              onChange={(val) => {
                setTax(val);
                handleSetFirstPage(true);
              }}
              options={[
                {
                  label: "All Tax",
                  value: "All Tax",
                },
              ]}
              className="min-w-[5.4rem]"
              suffixIcon={<SelectDownIcon width={14} height={8} />}
            />
            <Select
              allowClear
              showSearch
              filterOption={selectSearchOption}
              placeholder={"All Agreement"}
              onChange={(val) => {
                setAgreement(val);
                handleSetFirstPage(true);
              }}
              options={[
                {
                  label: "All Agreement",
                  value: "All Agreement",
                },
              ]}
              className="min-w-[9rem]"
              suffixIcon={<SelectDownIcon width={14} height={8} />}
            /> */}
          </Space>
        }
      />
    </AdminLayout>
  );
}
