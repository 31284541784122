import { Button } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { useState } from "react";
import * as XLSX from "xlsx";
import { toast } from "../../../helpers/toast";
// import { uploadTicketExcelAsync } from "../../../store/features/NP/network/networkAPI";
import moment from "moment";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import useExcelDownload from "../../../hooks/useExcelDownload";
import { uploadPaymentAsync } from "../../../store/features/admin/payment/paymentAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { stringToArray } from "../../../utils/array";
import { sliceString } from "../../../utils/string";
import { addMonthsToDate } from "../../../utils/time";
import CustomModal from "../../common/CustomModal";

type Props = {
  open: boolean;
  handleClose: () => void;
  data?: any;
  reRender: (val: boolean) => void;
  uploadedCount: (val: number, total: number) => void;
};

export default function ImportExcel({
  open,
  handleClose,
  data,
  reRender,
  uploadedCount,
}: Props) {
  const dispatch = useAppDispatch();
  const [fileName, setFileName] = useState("No file chosen");
  const [loading, setLoading] = useState(false);
  const [uploadedData, setUploadedData] = useState<any[]>([]);
  const { excelLoading, downloadExcel, handleExcelLoading } =
    useExcelDownload("Payment-List");
  const [uploadLoading, setUploadLoading] = useState(false);
  const { postLoading } = useAppSelector((state) => state.payment);

  const handleUploadFile = (file: any) => {
    if (
      file?.name?.endsWith(".xls") ||
      file?.name?.endsWith(".xlsx") ||
      file?.type === "application/vnd.ms-excel" ||
      file?.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setLoading(true);
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      const fileNameArr = stringToArray(file?.name, ".");
      setFileName(
        `${sliceString(file?.name, 30, "...")}.${
          fileNameArr?.[fileNameArr?.length - 1]
        }`
      );

      reader.onload = (e) => {
        const data = e?.target?.result;
        const workBook = XLSX.read(data, {
          type: "binary",
          cellDates: true,
          dateNF: "mm/dd/yyyy",
        });
        const sheetName = workBook.SheetNames[0];
        const sheet = workBook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet, { raw: false });
        // const filteredData = parsedData?.filter(
        //   (el: any) =>
        //     el?.["ID"] &&
        //     el?.["Tower Name"] &&
        //     (el?.["Per Minute Charge"] || el?.["Per Minute Charge"] === 0) &&
        //     el?.["Date"] &&
        //     el?.["Time"]
        // );

        setUploadedData(parsedData);
        setLoading(false);
      };
    } else {
      setLoading(false);
      toast("error", "Please upload only excel file");
    }
  };
  const handleDownloadExcel = async () => {
    handleExcelLoading(true);
    let excelData = [] as any[];

    await AXIOS.get(BASE_API.getPayableUser, {
      params: {
        month: moment().format("MMMM").toLowerCase(),
        year: moment().year(),
      },
    }).then((res) => {
      const responseData = res?.data?.getData as any[];

      responseData?.forEach((el) => {
        let data = {} as any;
        [
          ...Array(
            el?.yearlyPaymentNumber > 1 ? 12 / el?.yearlyPaymentNumber : 1
          ),
        ]?.forEach(() => {
          data["Proprietor ID"] = el?._id;
          data["Full Name"] = el?.fullName;
          data["Tower ID"] = el?.towerId;
          data["Tower Name"] = el?.towerName;
          data["Year"] = moment().year();
          data["Month"] = "";
          data["Date of Birth"] = moment(el?.dob, "YYYY-MM-DD").format(
            "MM/DD/YYYY"
          );
          data["Region ID"] = el?.region;
          data["Area ID"] = el?.area;
          data["Territory ID"] = el?.teritory;
          data["Address"] = el?.address;
          data["Status"] = "Paid";
          // data["Advance Amount"] = el?.paymentBankInfo?.advanceAmount;
          data["Monthly Rent Amount"] = el?.paymentBankInfo?.monthlyRentAmount;
          // data["Bank ID"] = el?.paymentBankInfo?.bank;
          data["Bank Name"] = el?.paymentBankInfo?.bankName;
          data["Branch ID"] = el?.paymentBankInfo?.branch;
          data["Branch Name"] = el?.paymentBankInfo?.branchName;
          data["Account Title"] = el?.paymentBankInfo?.accountTitle;
          data["Account Number"] = el?.paymentBankInfo?.accountNumber;
          data["Routing Number"] = el?.paymentBankInfo?.routingNumber;
          data["Next Payment Date"] = el?.nextPaymentDate
            ? addMonthsToDate(el?.nextPaymentDate, 12 / el?.yearlyPaymentNumber)
            : "";

          excelData.push(data);
        });
      });
    });

    downloadExcel(excelData);
  };
  const handleUpload = async () => {
    setUploadLoading(true);
    try {
      const uploadData = uploadedData?.map((el) => ({
        proprietor: el?.["Proprietor ID"],
        fullName: el?.["Full Name"],
        tower: el?.["Tower ID"],
        towerName: el?.["Tower Name"],
        year: el?.["Year"],
        month: el?.["Month"],
        dob: moment(new Date(el?.["Date"])).format("YYYY-MM-DD"),
        region: el?.["Region ID"],
        area: el?.["Area ID"],
        teritory: el?.["Territory ID"],
        address: el?.["Address"],
        status: el?.["Status"],
        advanceAmount: el?.["Advance Amount"],
        monthlyRentAmount: el?.["Monthly Rent Amount"],
        bankName: el?.["Bank Name"],
        bank: el?.["Bank ID"],
        branch: el?.["Branch ID"],
        accountTitle: el?.["Account Title"],
        accountNumber: el?.["Account Number"],
        branchName: el?.["Branch Name"],
        routingNumber: el?.["Routing Number"],
        nextPaymentDate: moment(new Date(el?.["Next Payment Date"])).format(
          "YYYY-MM-DD"
        ),
      }));

      if (uploadData.length > 0) {
        dispatch(
          uploadPaymentAsync({
            data: uploadData,
            others: {
              reRender,
              uploadedCount,
            },
          })
        );
      } else {
        toast("error", "Upload data not found");
      }
    } catch (error) {
      console.error("Error during the upload process", error);
      toast("error", "Error during the upload process");
    } finally {
      setUploadLoading(false);
    }
  };

  // function successDone(id: string) {
  //   setCountUpload((prev) => prev + 1);
  //   dispatch(
  //     towerTicketNotificationAsync({
  //       data: { towerTicketId: id },
  //       others: null,
  //     })
  //   );
  // }

  return (
    <CustomModal
      width={600}
      title="Excel Sheet"
      open={open}
      handleClose={!uploadLoading && !postLoading ? handleClose : () => {}}
    >
      <div className="p-6">
        <label className="text-[#3d4a5c] text-sm font-medium leading-normal mb-1.5 inline-block">
          Import Excel File
        </label>
        <Dragger
          name="file"
          action=""
          accept=".xlsx, .xls"
          maxCount={1}
          showUploadList={false}
          beforeUpload={() => false}
          onChange={(info) => handleUploadFile(info.file)}
          onDrop={(e) => handleUploadFile(e.dataTransfer.files[0])}
          // excelPostLoading ||
          disabled={loading || uploadLoading || postLoading}
          className="import_excel"
        >
          <Button
            className="flex justify-between items-center"
            size="large"
            disabled={loading || uploadLoading || postLoading}
            style={{ width: "100%" }}
          >
            <div className="flex items-center gap-2 text-sm font-normal font-roboto text-[#8797ae]">
              <span className="text-accent font-medium text-base leading-7 relative">
                Choose File
              </span>
              <span className="border-r border-solid border-l-0 border-y-0 border-others-border h-4" />
              {loading ? "Uploading..." : fileName}
            </div>
          </Button>
        </Dragger>

        <div className="px-4 py-[9px] bg-[#fffbe6] rounded-md border border-[#ffe58f] text-sm text-[#874d00] mt-4">
          <span className="font-medium">Warning! </span>
          <span>
            {/* For any blank column put N/A, otherwise data
            will be mismatch.  */}
            Upload only xlsx file. Your can see sample file,{" "}
          </span>
          <button
            className="text-[#1890ff] cursor-pointer text-sm font-medium bg-transparent border-none"
            onClick={handleDownloadExcel}
            disabled={excelLoading}
          >
            {excelLoading ? "downloading..." : "click here"}
          </button>
        </div>

        <div className="flex justify-center mt-7">
          <Button
            size="large"
            type="primary"
            className="w-72 !h-12"
            onClick={handleUpload}
            disabled={loading || uploadedData?.length === 0}
            loading={uploadLoading || postLoading}
          >
            Upload
          </Button>
        </div>
      </div>
    </CustomModal>
  );
}
