export default function EditIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="ml-[1px] mt-[3px]"
    >
      <path
        d="M9.8125 2.9375L12.0625 5.1875M13.1875 7.4375V13.625C13.1875 13.7742 13.1282 13.9173 13.0227 14.0227C12.9173 14.1282 12.7742 14.1875 12.625 14.1875H1.375C1.22582 14.1875 1.08274 14.1282 0.977253 14.0227C0.871763 13.9173 0.8125 13.7742 0.8125 13.625V2.375C0.8125 2.22582 0.871763 2.08274 0.977253 1.97725C1.08274 1.87176 1.22582 1.8125 1.375 1.8125H7.5625M7 10.25H4.75V8L11.5 1.25L13.75 3.5L7 10.25Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
