import { ReactNode } from "react";

type Props = {
  title: string;
  children: ReactNode;
};

export default function InfoItem({ title, children }: Props) {
  return (
    <div className="grid grid-cols-160">
      <h4 className="text-others-table-header text-base font-medium leading-7 py-1 px-1.5">
        {title}
      </h4>
      <span className="text-accent text-base font-normal leading-7 py-1 px-1.5 flex items-center">
        {children || "N/A"}
      </span>
    </div>
  );
}
