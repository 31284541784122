import { Button, Form, InputNumber, Select } from "antd";
import { useEffect } from "react";
import SelectDownIcon from "../../assets/icons/SelectDownIcon";
import { ArrayOption, selectSearchOption } from "../../helpers/siteConfig";
import { updateProprietorDocumentAsync } from "../../store/features/admin/proprietors/proprietorsAPI";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { NotificationType } from "../../types/redux";
import {
  findFiscalYearByValue,
  formatDate,
  generateFiscalYears,
} from "../../utils/time";
import CustomModal from "../common/CustomModal";
import FileUpload from "../common/FileUpload";

type Props = {
  data?: any;
  open: boolean;
  type: NotificationType;
  handleClose: (val?: boolean) => void;
  towerId: string;
  id: string;
};

const acceptFile = ".jpg, .jpeg, .pdf, .png";
const fileExtra = (
  <span className="text-accent text-[13px] font-normal font-roboto leading-normal">
    File Format: jpg /jpeg/pdf/png | File size('250-500')KB
  </span>
);

const typeList = {
  ag: "Agreement",
  tin: "TIN",
  tax: "TAX",
  nid: "NID",
  pp: "Passport",
};

export default function DocumentUpdateModal({
  type,
  open,
  handleClose,
  towerId,
  id,
  data,
}: Props) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { updateLoading } = useAppSelector((state) => state.proprietor);

  const onFinish = (values: any) => {
    const newValue = {
      name: `${typeList[type]} paper`,
      type: type,
      user: id,
      ...values,
    };

    dispatch(
      updateProprietorDocumentAsync({
        data: newValue,
        others: {
          id: towerId,
          reRender: handleClose,
        },
      })
    );
  };

  useEffect(() => {
    if ((id || towerId) && updateLoading === false) {
      form.setFieldsValue({
        fileLink: data?.fileLink || "",
        taxNumber: data?.taxNumber ? Number(data?.taxNumber) : undefined,
        paperDate: data?.paperDate
          ? findFiscalYearByValue(formatDate(data?.paperDate, "DD/MM/YYYY"))
          : undefined,
      });
    }
  }, [
    data?.fileLink,
    data?.paperDate,
    data?.taxNumber,
    form,
    id,
    towerId,
    updateLoading,
  ]);

  const title = type === "tax" ? "Income tax return" : "Document update";

  return (
    <CustomModal
      width={450}
      handleClose={handleClose}
      title={title}
      open={open}
    >
      <Form
        className="custom-form hide_star_mark p-4"
        layout="vertical"
        onFinish={onFinish}
        size="large"
        form={form}
      >
        <div className="pb-6">
          <Form.Item
            name={"fileLink"}
            label="Tax return"
            extra={fileExtra}
            valuePropName="fileUrl"
            rules={[
              {
                required: true,
                message: "Please upload tax return",
              },
            ]}
          >
            <FileUpload
              form={form}
              accept={acceptFile}
              name="fileLink"
              onChange={(url) => form.setFieldsValue({ fileLink: url })}
              fileType
            />
          </Form.Item>

          <Form.Item
            name={"taxNumber"}
            label="Return registration number"
            rules={[
              {
                required: true,
                message: "Please enter return registration number",
              },
              {
                validator: async (_: any, value: number) => {
                  if (!value) {
                    return Promise.resolve();
                  }

                  if (value === 0) {
                    return Promise.reject(
                      new Error("Return registration number can't be zero")
                    );
                  }

                  if (value < 0) {
                    return Promise.reject(
                      new Error("Return registration number can't be negative")
                    );
                  }

                  if (!Number.isInteger(value)) {
                    return Promise.reject(
                      new Error("Return registration number must be an integer")
                    );
                  }
                },
              },
            ]}
          >
            <InputNumber
              className="w-full"
              size="large"
              placeholder="XXX XXXX"
            />
          </Form.Item>

          <Form.Item
            name={"paperDate"}
            label="Fiscal year"
            rules={[
              {
                required: true,
                message: "Please enter fiscal year",
              },
            ]}
          >
            <Select
              placeholder="Select fiscal year"
              size="large"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={ArrayOption(generateFiscalYears(), "key", "value")}
              // disabled={viewId ? true : false}
              suffixIcon={<SelectDownIcon />}
              // onChange={(val) => {
              //   setSelectBank(val);
              //   form.setFieldsValue({ branch: undefined });
              // }}
            />

            {/* <Select placeholder="Select fiscal year">
          {generateFiscalYears().map((item) => (
            <Option key={item.key} value={item.value}>
              {item.key}
            </Option>
          ))}
        </Select> */}
          </Form.Item>
        </div>
        <div className="w-full justify-center flex gap-2">
          <Button type="primary" htmlType="submit" loading={updateLoading}>
            Update
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
}
