import { Button, Space } from "antd";
import moment from "moment";
import { ReactNode, useEffect, useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import BankIcon from "../../../assets/icons/BankIcon";
import DocumentIcon from "../../../assets/icons/DocumentIcon";
import LeftArrowIcon from "../../../assets/icons/LeftArrowIcon";
import PrintIcon from "../../../assets/icons/PrintIcon";
import TowerIcon from "../../../assets/icons/TowerIcon";
import UsersIcon from "../../../assets/icons/UsersIcon";
import AVATAR from "../../../assets/images/avater.png";
import IconButton from "../../../components/common/IconButton";
import InputTitle from "../../../components/common/InputTitle";
import Loader from "../../../components/common/Loader";
import DocumentItem from "../../../components/ui/DocumentItem";
import InfoItem from "../../../components/ui/InfoItem";
import Subheader from "../../../components/ui/Subheader";
import { IMAGE_URL } from "../../../helpers/config";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  getProprietorPaymentHistoryAsync,
  getSingleProprietorsAsync,
} from "../../../store/features/admin/proprietors/proprietorsAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { stringToArray } from "../../../utils/array";
import { sumOfArray } from "../../../utils/number";
import { capitalize } from "../../../utils/string";
import { formatDate } from "../../../utils/time";

export default function ProprietorsManageInformation() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const componentRef = useRef() as any;
  const { singleProprietor, viewLoading, loading, paymentHistory } =
    useAppSelector((state) => state.proprietor);
  // console.log(singleProprietor);
  const pathArr = stringToArray(location?.pathname, "/");
  const navLink = pathArr?.slice(0, pathArr?.length - 1)?.join("/");

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (id) {
      dispatch(getSingleProprietorsAsync({ params: { id } }));
      dispatch(getProprietorPaymentHistoryAsync({ params: { id } }));
    }
  }, [dispatch, id]);

  const monthOrder = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];

  const sortedPayment = [...paymentHistory].sort((a, b) => {
    const monthA = a.month.toLowerCase();
    const monthB = b.month.toLowerCase();
    return monthOrder.indexOf(monthA) - monthOrder.indexOf(monthB);
  });

  return (
    <AdminLayout title={`Information of ${singleProprietor?.fullName}`}>
      {viewLoading || loading ? (
        <Loader />
      ) : (
        <section className="roboto_font">
          <Subheader title={`Information of ${singleProprietor?.fullName}`}>
            <Space size="middle">
              <Link to={"/" + navLink}>
                <IconButton
                  className="!border-others-border !text-sm !font-normal !rounded-lg !border !text-body !bg-transparent font-roboto"
                  type="default"
                  icon={<LeftArrowIcon />}
                >
                  <span>Back</span>
                </IconButton>
              </Link>
            </Space>
          </Subheader>

          <div className="max-w-[800px] mx-auto p-6 mt-6">
            <InputTitle
              icon={<UsersIcon />}
              title="Proprietor’s Information"
              hideBorder
            />

            <div className="grid md:grid-cols-115 gap-2.5">
              <div>
                <img
                  className="max-w-[142px] h-full w-full object-cover"
                  src={
                    singleProprietor?.photo?.[0]
                      ? `${IMAGE_URL + singleProprietor?.photo?.[0]}`
                      : AVATAR
                  }
                  alt=""
                />
              </div>
              <div className="info_items bg-[#f9fafb]">
                <InfoItem title="Proprietor’s Name:">
                  {singleProprietor?.fullName}
                </InfoItem>
                <InfoItem title="Date of Birth:">
                  {formatDate(singleProprietor?.dob)}
                </InfoItem>
                <InfoItem title="Mobile Number:">
                  {singleProprietor?.activeMobileNumber}
                </InfoItem>
                <InfoItem title="Email Address:">
                  {singleProprietor?.email}
                </InfoItem>
              </div>
            </div>
            <div className="info_items mt-2.5 mb-1.5">
              <InfoItem title="Father’s Name:">
                {singleProprietor?.fatherName}
              </InfoItem>
              <InfoItem title="Mother’s Name:">
                {singleProprietor?.montherName}
              </InfoItem>
              <InfoItem title="NID No:">{singleProprietor?.nid}</InfoItem>
              <InfoItem title="Passport No:">
                {singleProprietor?.passportNumber}
              </InfoItem>
              <InfoItem title="TAX No:">
                {singleProprietor?.document?.taxNumber}
              </InfoItem>
              <InfoItem title="TIN No:">
                {singleProprietor?.document?.tinNumber}
              </InfoItem>
              <InfoItem title="Address:">{singleProprietor?.address}</InfoItem>
            </div>

            <InputTitle
              icon={<TowerIcon />}
              title="Tower Information"
              hideBorder
            />

            <div className="info_items mb-2">
              <InfoItem title="Tower/Site name:">
                {singleProprietor?.towerId?.towerName}
              </InfoItem>
              <InfoItem title="Site Code:">
                {singleProprietor?.towerId?.siteCode}
              </InfoItem>
              <InfoItem title="Region:">
                {singleProprietor?.towerId?.regionName}
              </InfoItem>
              <InfoItem title="Area:">
                {singleProprietor?.towerId?.areaName}
              </InfoItem>
              {/* <InfoItem title="Territory:">
              {singleProprietor?.towerId?.teritoryName}
            </InfoItem> */}
            </div>

            <InputTitle
              icon={<BankIcon />}
              title="Payment & Bank Information"
              hideBorder
              className="!mt-0"
            />

            <div className="info_items mb-1.5">
              <InfoItem title="Advance Amount:">
                {singleProprietor?.paymentBankInfo?.advanceAmount} TK
              </InfoItem>
              <InfoItem title="Monthly Rent:">
                {singleProprietor?.paymentBankInfo?.monthlyRentAmount} TK
              </InfoItem>
              <InfoItem title="Bank Name:">
                {singleProprietor?.paymentBankInfo?.bankName}
              </InfoItem>
              <InfoItem title="Account Title:">
                {singleProprietor?.paymentBankInfo?.accountTitle}
              </InfoItem>
              <InfoItem title="Routing No:">
                {singleProprietor?.paymentBankInfo?.routingNumber}
              </InfoItem>
              <InfoItem title="Branch:">
                {singleProprietor?.paymentBankInfo?.branchName}
              </InfoItem>
            </div>

            <InputTitle
              icon={<DocumentIcon />}
              title="Document Attachment"
              hideBorder
            />

            <div className="info_items mb-1.5">
              <DocumentItem
                title="Agreement Paper"
                date={singleProprietor?.document?.aggrementPaperExpireDate}
                file={singleProprietor?.document?.aggrementPaper}
                id={singleProprietor?._id || ""}
                type="ag"
                towerId={singleProprietor?.towerId}
              />
              <DocumentItem
                title="Tax Certificate"
                date={singleProprietor?.document?.taxCertificateExpiryDate}
                file={singleProprietor?.document?.TaxCertificate}
                id={singleProprietor?._id || ""}
                type="tax"
                towerId={singleProprietor?.towerId}
                number={singleProprietor?.document?.taxNumber}
              />
              <DocumentItem
                title="TIN Certificate"
                file={singleProprietor?.document?.TinCertificate}
                id={singleProprietor?._id || ""}
                type="tin"
                towerId={singleProprietor?.towerId}
              />
              <DocumentItem
                title="NID"
                file={singleProprietor?.document?.nid}
                id={singleProprietor?._id || ""}
                type="nid"
                towerId={singleProprietor?.towerId}
              />
              <DocumentItem
                title="Passport"
                file={singleProprietor?.document?.passport}
                id={singleProprietor?._id || ""}
                type="pp"
                towerId={singleProprietor?.towerId}
              />
            </div>
          </div>

          {/* <div className="w-full justify-center flex gap-2 pt-3">
          <Link className="delete_btn" to={navLink}>
            <Button style={{ width: 130 }} size="large">
              Delete
            </Button>
          </Link>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            style={{ width: 120 }}
          >
            Update
          </Button>
        </div> */}
        </section>
      )}

      {!loading && sortedPayment?.length ? (
        <div className="mt-6">
          <Space className="bg-[#f0f3f8] px-4 py-2.5 justify-between w-full">
            <h4 className="text-header text-lg font-medium font-roboto leading-[30px]">
              Payment History
            </h4>

            <div className="flex items-center gap-3">
              {/* <DatePicker
                rootClassName="date_select"
                size="large"
                onChange={handleDateChange}
                picker="year"
                defaultValue={dayjs()}
              /> */}
              <Button size="large" onClick={handlePrint} icon={<PrintIcon />} />
            </div>
          </Space>
          <div className="overflow-x-auto payment_details" ref={componentRef}>
            <h4 className="text-header text-center text-lg py-3 font-medium font-roboto leading-[30px] hidden">
              Payment History of {singleProprietor?.fullName}
            </h4>
            <table className="w-full border border-solid border-others-gray text-left border-collapse border-b-0 last:border-l-0 last:border-r-0 font-medium">
              <tr className="bg-[#f0f3f8]">
                {tableHead?.map((el, idx) => (
                  <th
                    className={`text-sm font-medium text-header border border-solid border-others-gray text-left py-2.5 px-1.5 whitespace-nowrap ${
                      idx === 0 ? "w-8 text-center" : ""
                    }`}
                    key={el + idx}
                  >
                    {el}
                  </th>
                ))}
                {/* <th /> */}
              </tr>
              {sortedPayment?.map((el, idx) => (
                <tr key={el?._id}>
                  <TableItem
                    isPaid={el?.status === "Paid"}
                    className="text-center"
                  >
                    {idx + 1}
                  </TableItem>
                  <TableItem isPaid={el?.status === "Paid"}>
                    {capitalize(el?.month)}
                  </TableItem>
                  <TableItem isPaid={el?.status === "Paid"}>
                    {el?.createdAt
                      ? moment(el?.createdAt).format("MMM D, YYYY h:mm:ss A")
                      : ""}
                  </TableItem>
                  <TableItem isPaid={el?.status === "Paid"}>
                    {el?.accountTitle}
                  </TableItem>
                  <TableItem isPaid={el?.status === "Paid"}>
                    {el?.accountNumber}
                  </TableItem>
                  <TableItem isPaid={el?.status === "Paid"}>
                    {el?.routingNumber}
                  </TableItem>
                  <TableItem isPaid={el?.status === "Paid"}>
                    {el?.branchName}
                  </TableItem>
                  <TableItem
                    isPaid={el?.status === "Paid"}
                    className="text-end"
                  >
                    {el?.monthlyRentAmount}
                  </TableItem>
                  <TableItem className="text-center">
                    <span
                      className={`status !px-2 !py-0.5 !text-xs ${
                        el?.status === "Paid"
                          ? "status-complete !bg-[#e4f6ed]"
                          : "status-pending"
                      }`}
                    >
                      {capitalize(el?.status)}
                    </span>
                  </TableItem>
                  {/* <TableItem>
                    {el?.status === "Paid" ? (
                      <button className="text-status-info-text bg-transparent cursor-pointer border border-solid border-others-border rounded-lg">
                        <span className="w-[84px] block py-[3px]">
                          <ViewIcon />
                          <span className="text-sm font-medium leading-normal ml-1.5 relative -top-[1px]">
                            Payslip
                          </span>
                        </span>
                      </button>
                    ) : (
                      <button
                        className="text-primary disabled:text-others-gray bg-transparent cursor-pointer border border-solid border-others-border rounded-lg disabled:cursor-default disabled:border-transparent"
                        disabled={
                          data?.find((item) => item?.status === "Due")?.id !==
                          el?.id
                        }
                      >
                        <span className="text-sm font-medium leading-normal relative -top-[1px] w-[84px] block py-1">
                          Payment
                        </span>
                      </button>
                    )}
                  </TableItem> */}
                </tr>
              ))}
              <tr>
                <td colSpan={7} />
                <TableItem className="text-right text-white bg-accent">
                  {/* {paymentData?.[0]?.amount} */}
                  {sumOfArray(sortedPayment, "monthlyRentAmount")}
                </TableItem>
              </tr>
            </table>
          </div>
        </div>
      ) : null}
    </AdminLayout>
  );
}

type Props = {
  children: ReactNode;
  isPaid?: boolean;
  className?: string;
};

const TableItem = ({ children, isPaid, className = "" }: Props) => (
  <td
    className={`${
      isPaid ? "text-primary" : "text-header"
    } text-sm font-normal border border-solid border-others-gray text-left py-2.5 px-1.5 whitespace-nowrap ${className}`}
  >
    {children}
  </td>
);

const tableHead = [
  "#SL",
  "Months",
  "Date",
  "Account Title",
  "Account No.",
  "Routing No.",
  "Branch",
  "Amount (TK)",
  "Status",
];
