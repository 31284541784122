import { Button, Empty, Space } from "antd";
import { ReactNode, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "../../assets/icons/PrintIcon";
import EmptyImage from "../../assets/images/empty.svg";

type Props = {
  data: any;
};

export default function DivisionWiseRentalCost({ data }: Props) {
  const componentRef = useRef() as any;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // const handleDateChange: DatePickerProps["onChange"] = (date, dateString) => {
  //   console.log(date, dateString);
  // };

  return (
    <div className="md:col-span-2">
      <div className="flex justify-between gap-5 items-center flex-wrap filter_section mb-4">
        <h3 className="text-text-body text-xl font-semibold leading-loose">
          Region Wise Rental Cost
        </h3>

        <div className="ml-auto">
          <Space size="small">
            <Button
              size="large"
              onClick={handlePrint}
              icon={<PrintIcon />}
              disabled={!data?.length}
            />
            {/* <DatePicker
              rootClassName="date_select"
              size="large"
              onChange={handleDateChange}
              picker="month"
              defaultValue={dayjs()}
              format="MMMM YYYY"
              disabled
            /> */}
          </Space>
        </div>
      </div>

      <div
        className="rounded-md border border-solid border-[#adebd9] overflow-x-auto common_print"
        ref={componentRef}
      >
        <h4 className="text-others-table-header text-center text-lg py-3 font-medium font-roboto leading-[30px] hidden">
          Region Wise Rental Cost
        </h4>

        <table className="w-full border border-solid border-collapse color_table">
          <thead>
            <tr className="bg-[#edfbf7]">
              {tableHead?.map((el, idx) => (
                <th
                  className={`text-base font-medium text-others-table-header border border-solid border-[#adebd9] border-t-transparent first:border-l-transparent last:border-r-transparent py-0.5 px-1.5 whitespace-nowrap text-center ${
                    idx === 0 ? "w-[60px]" : ""
                  }`}
                  key={el + idx}
                  style={{
                    borderTopLeftRadius: idx === 0 ? "8px" : "",
                    borderTopRightRadius:
                      idx === tableHead.length - 1 ? "8px" : "",
                  }}
                >
                  <span dangerouslySetInnerHTML={{ __html: el }} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.length ? (
              data?.map((el: any, idx: number) => (
                <tr key={el?._id}>
                  <TableItem
                    className={`text-center ${
                      data?.length - 1 === idx ? "border-b-transparent" : ""
                    }`}
                  >
                    {idx + 1}
                  </TableItem>
                  <TableItem
                    className={
                      data?.length - 1 === idx ? "border-b-transparent" : ""
                    }
                  >
                    {el?.regionName}
                  </TableItem>
                  <TableItem
                    className={
                      data?.length - 1 === idx ? "border-b-transparent" : ""
                    }
                  >
                    {el?.totalSite || 0}
                  </TableItem>
                  {/* <TableItem
                  className={
                    data?.length - 1 === idx ? "border-b-transparent" : ""
                  }
                >
                  {el?.dueTower}
                </TableItem> */}
                  <TableItem
                    className={
                      data?.length - 1 === idx ? "border-b-transparent" : ""
                    }
                  >
                    {el?.totalAmount}
                  </TableItem>
                  {/* <TableItem
                  className={
                    data?.length - 1 === idx ? "border-b-transparent" : ""
                  }
                >
                  <div className="flex justify-center text-center">
                    <Link to={``}>
                      <button className="w-9 h-9 rounded-full flex justify-center items-center bg-[#edfbf7] border border-solid border-[#ADEBD9] text-primary cursor-pointer">
                        <RightArrowIcon color="currentColor" />
                      </button>
                    </Link>
                  </div>
                </TableItem> */}
                </tr>
              ))
            ) : (
              <tr
                className={`text-base font-medium text-others-table-header border border-solid border-[#adebd9] !border-transparent`}
              >
                <td colSpan={4}>
                  <Empty
                    image={EmptyImage}
                    imageStyle={{ marginBottom: 0, height: 70 }}
                    rootClassName="py-10"
                    description={
                      <span className="text-[#8797ae] block -mt-2 text-sm font-normal font-roboto leading-7">
                        Empty
                      </span>
                    }
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const tableHead = [
  "#",
  "Region",
  "Total Site/Tower",
  // "Total Site/<br />Tower",
  // "Due (Site/<br />Tower)",
  "Due (BDT)",
  // "Action",
];

// const data = [
//   {
//     _id: 1,
//     region: "Dhaka",
//     totalTower: "8,653",
//     dueTower: "6,000",
//     due: "600,000",
//   },
//   {
//     _id: 2,
//     region: "Chittagong",
//     totalTower: "8,653",
//     dueTower: "6,000",
//     due: "600,000",
//   },
//   {
//     _id: 3,
//     region: "Barisal",
//     totalTower: "8,653",
//     dueTower: "6,000",
//     due: "600,000",
//   },
//   {
//     _id: 4,
//     region: "Khulna",
//     totalTower: "8,653",
//     dueTower: "6,000",
//     due: "600,000",
//   },
//   {
//     _id: 5,
//     region: "Mymensingh",
//     totalTower: "8,653",
//     dueTower: "6,000",
//     due: "600,000",
//   },
//   {
//     _id: 6,
//     region: "Rajshahi",
//     totalTower: "8,653",
//     dueTower: "6,000",
//     due: "600,000",
//   },
//   {
//     _id: 7,
//     region: "Rangpur",
//     totalTower: "8,653",
//     dueTower: "6,000",
//     due: "600,000",
//   },
//   {
//     _id: 8,
//     region: "Sylhet",
//     totalTower: "8,653",
//     dueTower: "6,000",
//     due: "600,000",
//   },
// ];

type ItemProps = {
  children: ReactNode;
  className?: string;
};

const TableItem = ({ children, className = "" }: ItemProps) => (
  <td
    className={`text-body text-basefont-medium border border-solid border-[#adebd9] py-1.5 px-1.5 whitespace-nowrap text-center first:border-l-transparent last:border-r-transparent ${className}`}
  >
    {children}
  </td>
);
