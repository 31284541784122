import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import {
  CommonOthers,
  // CommonOthers,
  ErrorMessage,
  GetSingleDataParams,
  Params,
  // GetNetworkLogData,
  // GetSingleDataParams,
  // Params,
  PostRequest,
} from "../../../../types/redux";
import {
  GetProprietorData,
  ProprietorData,
} from "../../../../types/redux/admin/proprietors";

// create a proprietors
export const createProprietorAsync = createAsyncThunk<
  null,
  PostRequest<ProprietorData, CommonOthers>
>(
  "admin-proprietors/createProprietors",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createProprietor, data);
      reRender(true);
      toast("success", "Proprietor create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Proprietor create fail");
      return rejectWithValue(error);
    }
  }
);

// update proprietors document
export const updateProprietorDocumentAsync = createAsyncThunk<
  null,
  PostRequest<any, CommonOthers>
>(
  "admin-proprietors/updateProprietorDocument",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(
        `${
          BASE_API.updateProprietorDocument
        }/${id}/${new Date().getFullYear()}`,
        data
      );
      reRender(true);
      toast("success", `${data?.name} update successfully`);
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || `${data?.name} update fail`);
      return rejectWithValue(error);
    }
  }
);

// get all proprietors
export const getAllProprietorsAsync = createAsyncThunk<
  GetProprietorData[],
  Params<any>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-proprietors/getAllProprietors",
  async ({ params }, { rejectWithValue }) => {
    try {
      const getData = await AXIOS.get(BASE_API.getAllProprietor, {
        params,
      });

      return getData.data?.getData as any[];
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get all proprietors data fail");
      return rejectWithValue(error);
    }
  }
);

// get single Proprietors
export const getSingleProprietorsAsync = createAsyncThunk<
  GetProprietorData,
  Params<GetSingleDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-proprietors/getSingleProprietors",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(
        `${BASE_API.getSingleProprietor}/${params?.id}`
      );

      return data.data?.getData as GetProprietorData;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get proprietor fail");
      return rejectWithValue(error);
    }
  }
);

// get single Proprietors
export const getProprietorPaymentHistoryAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-proprietors/getProprietorPaymentHistory",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(
        `${BASE_API.getProprietorPaymentHistory}/${params?.id}`
      );

      return data.data?.getData as any;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get payment history fail");
      return rejectWithValue(error);
    }
  }
);
