import { Button, Space } from "antd";
import moment from "moment";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import LeftArrowIcon from "../../../assets/icons/LeftArrowIcon";
import PrintIcon from "../../../assets/icons/PrintIcon";
import UsersIcon from "../../../assets/icons/UsersIcon";
import AVATAR from "../../../assets/images/avater.png";
import IconButton from "../../../components/common/IconButton";
import InputTitle from "../../../components/common/InputTitle";
import Loader from "../../../components/common/Loader";
import InfoItem from "../../../components/ui/InfoItem";
import Subheader from "../../../components/ui/Subheader";
import { IMAGE_URL } from "../../../helpers/config";
import AdminLayout from "../../../layouts/AdminLayout";
import { getSinglePaymentsAsync } from "../../../store/features/admin/payment/paymentAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { capitalize } from "../../../utils/string";
import { formatDate } from "../../../utils/time";

const navLink = "/admin/payment";

export default function PaymentDetails() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const componentRef = useRef() as any;
  const [paymentData, setPaymentData] = useState<any[]>([]);
  const { viewLoading, singlePayment } = useAppSelector(
    (state) => state.payment
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // const handleDateChange: DatePickerProps["onChange"] = (date, dateString) => {
  //   console.log(date, dateString);
  // };

  useEffect(() => {
    if (id) {
      dispatch(getSinglePaymentsAsync({ params: { id: id || "" } }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id && singlePayment) {
      setPaymentData([
        {
          month: singlePayment?.month,
          date: singlePayment?.createdAt,
          accountTitle: singlePayment?.accountTitle,
          accountNumber: singlePayment?.accountNumber,
          routingNumber: singlePayment?.routingNumber,
          branchName: singlePayment?.proprietor?.paymentBankInfo?.branchName,
          amount: singlePayment?.monthlyRentAmount,
          status: singlePayment?.status,
          _id: singlePayment?._id,
        },
      ]);
    }
  }, [id, singlePayment]);

  return (
    <AdminLayout title={`Payment Details of ${singlePayment?.fullName}`}>
      {viewLoading ? (
        <Loader />
      ) : (
        <section className="roboto_font">
          <Subheader title={`Payment Details of ${singlePayment?.fullName}`}>
            <Space size="middle">
              <Link to={navLink}>
                <IconButton
                  className="!border-others-border !text-sm !font-normal !rounded-lg !border !text-body !bg-transparent font-roboto"
                  type="default"
                  icon={<LeftArrowIcon />}
                >
                  <span>Back</span>
                </IconButton>
              </Link>
            </Space>
          </Subheader>

          <div className="mt-6">
            <InputTitle
              icon={<UsersIcon />}
              title="Proprietor’s Information"
              hideBorder
            />

            <div className="grid md:grid-cols-140 gap-5 mt-4">
              <div>
                <img
                  className="max-w-[142px] h-full w-full object-cover"
                  // src={AVATAR}
                  src={
                    singlePayment?.proprietor?.photo?.[0]
                      ? `${IMAGE_URL + singlePayment?.proprietor?.photo?.[0]}`
                      : AVATAR
                  }
                  alt=""
                />
              </div>
              <div className="info_items show_answer_left_border bg-[#f9fafb]">
                <InfoItem title="Proprietor’s Name:">
                  {singlePayment?.proprietor?.fullName}
                </InfoItem>
                <InfoItem title="Date of Birth:">
                  {formatDate(singlePayment?.proprietor?.dob)}
                </InfoItem>
                <InfoItem title="Mobile Number:">
                  {singlePayment?.proprietor?.activeMobileNumber}
                </InfoItem>
                <InfoItem title="Email ID:">
                  {singlePayment?.proprietor?.email}
                </InfoItem>
                <InfoItem title="Address:">
                  {singlePayment?.proprietor?.address}
                </InfoItem>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <Space className="bg-[#f0f3f8] px-4 py-2.5 justify-between w-full">
              <h4 className="text-header text-lg font-medium font-roboto leading-[30px]">
                Payment History
              </h4>

              <div className="flex items-center gap-3">
                {/* <DatePicker
                rootClassName="date_select"
                size="large"
                onChange={handleDateChange}
                picker="year"
                defaultValue={dayjs()}
              /> */}
                <Button
                  size="large"
                  onClick={handlePrint}
                  icon={<PrintIcon />}
                />
              </div>
            </Space>
            <div className="overflow-x-auto payment_details" ref={componentRef}>
              <h4 className="text-header text-center text-lg py-3 font-medium font-roboto leading-[30px] hidden">
                Payment History of {singlePayment?.fullName}
              </h4>
              <table className="w-full border border-solid border-others-gray text-left border-collapse border-b-0 last:border-l-0 last:border-r-0 font-medium">
                <tr className="bg-[#f0f3f8]">
                  {tableHead?.map((el, idx) => (
                    <th
                      className={`text-sm font-medium text-header border border-solid border-others-gray text-left py-2.5 px-1.5 whitespace-nowrap ${
                        idx === 0 ? "w-8 text-center" : ""
                      }`}
                      key={el + idx}
                    >
                      {el}
                    </th>
                  ))}
                  {/* <th /> */}
                </tr>
                {paymentData?.map((el, idx) => (
                  <tr key={el?._id}>
                    <TableItem
                      isPaid={el?.status === "Paid"}
                      className="text-center"
                    >
                      {idx + 1}
                    </TableItem>
                    <TableItem isPaid={el?.status === "Paid"}>
                      {capitalize(el?.month)}
                    </TableItem>
                    <TableItem isPaid={el?.status === "Paid"}>
                      {el?.date
                        ? moment(el?.date).format("MMM D, YYYY h:mm:ss A")
                        : ""}
                    </TableItem>
                    <TableItem isPaid={el?.status === "Paid"}>
                      {el?.accountTitle}
                    </TableItem>
                    <TableItem isPaid={el?.status === "Paid"}>
                      {el?.accountNumber}
                    </TableItem>
                    <TableItem isPaid={el?.status === "Paid"}>
                      {el?.routingNumber}
                    </TableItem>
                    <TableItem isPaid={el?.status === "Paid"}>
                      {el?.branchName}
                    </TableItem>
                    <TableItem
                      isPaid={el?.status === "Paid"}
                      className="text-end"
                    >
                      {el?.amount}
                    </TableItem>
                    <TableItem className="text-center">
                      <span
                        className={`status !px-2 !py-0.5 !text-xs ${
                          el?.status === "Paid"
                            ? "status-complete !bg-[#e4f6ed]"
                            : "status-pending"
                        }`}
                      >
                        {capitalize(el?.status)}
                      </span>
                    </TableItem>
                    {/* <TableItem>
                    {el?.status === "Paid" ? (
                      <button className="text-status-info-text bg-transparent cursor-pointer border border-solid border-others-border rounded-lg">
                        <span className="w-[84px] block py-[3px]">
                          <ViewIcon />
                          <span className="text-sm font-medium leading-normal ml-1.5 relative -top-[1px]">
                            Payslip
                          </span>
                        </span>
                      </button>
                    ) : (
                      <button
                        className="text-primary disabled:text-others-gray bg-transparent cursor-pointer border border-solid border-others-border rounded-lg disabled:cursor-default disabled:border-transparent"
                        disabled={
                          data?.find((item) => item?.status === "Due")?.id !==
                          el?.id
                        }
                      >
                        <span className="text-sm font-medium leading-normal relative -top-[1px] w-[84px] block py-1">
                          Payment
                        </span>
                      </button>
                    )}
                  </TableItem> */}
                  </tr>
                ))}
                <tr>
                  <td colSpan={7} />
                  <TableItem className="text-right text-white bg-accent">
                    {paymentData?.[0]?.amount}
                  </TableItem>
                </tr>
              </table>
            </div>
          </div>
        </section>
      )}
    </AdminLayout>
  );
}

type Props = {
  children: ReactNode;
  isPaid?: boolean;
  className?: string;
};

const TableItem = ({ children, isPaid, className = "" }: Props) => (
  <td
    className={`${
      isPaid ? "text-primary" : "text-header"
    } text-sm font-normal border border-solid border-others-gray text-left py-2.5 px-1.5 whitespace-nowrap ${className}`}
  >
    {children}
  </td>
);

const tableHead = [
  "#SL",
  "Months",
  "Date",
  "Account Title",
  "Account No.",
  "Routing No.",
  "Branch",
  "Amount (TK)",
  "Status",
];

// const data = [
//   {
//     month: "January",
//     date: "Jan 11, 2023 11:53:27 PM",
//     accountTitle: "Mr. Albert Flores",
//     accountNo: "xxx xxxx xxxxx",
//     routingNo: "xxx xxxx xxxxx",
//     branch: "Mirpur-10",
//     amount: "80,000",
//     status: "Paid",
//     id: "1",
//   },
//   {
//     month: "February",
//     date: "Feb 18, 2023 4:14:34 PM",
//     accountTitle: "Mr. Albert Flores",
//     accountNo: "xxx xxxx xxxxx",
//     routingNo: "xxx xxxx xxxxx",
//     branch: "Mirpur-10",
//     amount: "80,000",
//     status: "Due",
//     id: "2",
//   },
//   {
//     month: "March",
//     date: "Mar 22, 2024 10:10:28 AM",
//     accountTitle: "Mr. Albert Flores",
//     accountNo: "xxx xxxx xxxxx",
//     routingNo: "xxx xxxx xxxxx",
//     branch: "Mirpur-10",
//     amount: "80,000",
//     status: "Due",
//     id: "3",
//   },
// ];
