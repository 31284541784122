import { Button, Form, Input } from "antd";
import PasswordLock from "../../../assets/icons/PasswordLock";

export default function ChangePassword() {
  const [form] = Form.useForm();

  const onFinish = () => {
    // dispatch(
    //   loginUserAsync({
    //     data: {
    //       ...values,
    //       isPG: false,
    //       mobileToken: notificationToken,
    //       loginRole: "ADMIN",
    //     },
    //     others: {
    //       reRender,
    //     },
    //   })
    // );
  };

  //   function reRender(val: boolean) {
  //     if (val) navigate("/admin/dashboard");
  //   }

  return (
    <div className="font-roboto roboto_font rounded-md border border-status-info-bg border-solid p-4 md:w-[460px]">
      <h5 className="mb-6 text-body text-xl font-semibold leading-loose">
        Change Password
      </h5>
      <Form
        name="custom-form hide_star_mark"
        className="custom-form login-form"
        layout="vertical"
        onFinish={onFinish}
        requiredMark="optional"
        size="large"
        form={form}
      >
        <Form.Item
          name="oldPassword"
          label="Old Password"
          rules={[
            {
              required: true,
              message: "Please enter current password",
            },
          ]}
        >
          <Input.Password
            className="password_input"
            prefix={<PasswordLock />}
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item
          name="password"
          label="New Password"
          rules={[
            {
              required: true,
              message: "Please enter new password",
            },
            {
              min: 6,
              message: "Password must be minimum 6 characters.",
            },
          ]}
        >
          <Input.Password
            className="password_input"
            prefix={<PasswordLock />}
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="Confirm Password"
          rules={[
            {
              required: true,
              message: "Please enter confirm password",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Password and Confirm password do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password
            className="password_input"
            prefix={<PasswordLock />}
            placeholder="Password"
          />
        </Form.Item>
        <div className="flex justify-center mt-6">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="w-[150px]"
            // loading={loginLoading}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}
