import { Button, Progress, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DownloadIcon from "../../../assets/icons/DownloadIcon";
import ImportExcel from "../../../components/admin/payment/ImportExcel";
import CustomTable from "../../../components/common/CustomTable";
import IconButton from "../../../components/common/IconButton";
import Subheader from "../../../components/ui/Subheader";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { addKeyInArray } from "../../../helpers/siteConfig";
import useExcelDownload from "../../../hooks/useExcelDownload";
import useModal from "../../../hooks/useModal";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import { getPayableUserAsync } from "../../../store/features/admin/dashboard/dashboardAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { TableData } from "../../../types/redux";
import { countRow, percentage } from "../../../utils/number";
import { addMonthsToDate, formatDate } from "../../../utils/time";

export default function PayableUser() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { open, toggle, handleOpen } = useModal();
  const { excelLoading, downloadExcel, handleExcelLoading } =
    useExcelDownload("Payment-List");
  const { limit, page, handlePagination, handleLimit } = useTable(1, 10);
  const { getPayableUser, loading } = useAppSelector(
    (state) => state.adminDashboard
  );
  const { postLoading } = useAppSelector((state) => state.payment);
  const allPayableUserData = getPayableUser?.slice(
    (page - 1) * limit,
    limit * page
  );

  const [uploadCount, setUploadCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const handleDownloadExcel = async () => {
    handleExcelLoading(true);
    let excelData = [] as any[];

    await AXIOS.get(BASE_API.getPayableUser, {
      params: {
        month: moment().format("MMMM").toLowerCase(),
        year: moment().year(),
      },
    }).then((res) => {
      const responseData = res?.data?.getData as any[];

      responseData?.forEach((el) => {
        let data = {} as any;
        [
          ...Array(
            el?.yearlyPaymentNumber > 1 ? 12 / el?.yearlyPaymentNumber : 1
          ),
        ]?.forEach(() => {
          data["Proprietor ID"] = el?._id;
          data["Full Name"] = el?.fullName;
          data["Tower ID"] = el?.towerId;
          data["Tower Name"] = el?.towerName;
          data["Year"] = moment().year();
          data["Month"] = "";
          data["Date of Birth"] = moment(el?.dob, "YYYY-MM-DD").format(
            "MM/DD/YYYY"
          );
          data["Region ID"] = el?.region;
          data["Area ID"] = el?.area;
          data["Territory ID"] = el?.teritory;
          data["Address"] = el?.address;
          data["Status"] = "Paid";
          // data["Advance Amount"] = el?.paymentBankInfo?.advanceAmount;
          data["Monthly Rent Amount"] = el?.paymentBankInfo?.monthlyRentAmount;
          // data["Bank ID"] = el?.paymentBankInfo?.bank;
          data["Bank Name"] = el?.paymentBankInfo?.bankName;
          data["Branch ID"] = el?.paymentBankInfo?.branch;
          data["Branch Name"] = el?.paymentBankInfo?.branchName;
          data["Account Title"] = el?.paymentBankInfo?.accountTitle;
          data["Account Number"] = el?.paymentBankInfo?.accountNumber;
          data["Routing Number"] = el?.paymentBankInfo?.routingNumber;
          data["Next Payment Date"] = el?.nextPaymentDate
            ? addMonthsToDate(el?.nextPaymentDate, 12 / el?.yearlyPaymentNumber)
            : "";

          excelData.push(data);
        });
      });
    });

    downloadExcel(excelData);
  };

  const columns: ColumnsType<TableData<any>> = [
    {
      title: "#",
      align: "center",
      width: 60,
      render: (_, __, idx) => countRow(page, limit, idx),
    },
    {
      title: "Proprietor’s name",
      dataIndex: "fullName",
      render: (val) => (
        <span className="text-header text-base font-normal">{val}</span>
      ),
    },

    {
      title: "Yearly payment frequency",
      dataIndex: "yearlyPaymentNumber",
      align: "center",
      // render: (val) => (
      //   <span className="text-header text-base font-normal">{val}</span>
      // ),
    },
    {
      title: "Monthly Rent",
      dataIndex: "paymentBankInfo",
      align: "center",
      render: (val) => val?.monthlyRentAmount + " TK",
    },
    {
      title: "Number of month to be paid",
      dataIndex: "yearlyPaymentNumber",
      align: "center",
      render: (val) => (val > 1 ? parseInt(12 / val + "") : val),
    },
    {
      title: "Total amount",
      // dataIndex: "paymentBankInfo",
      align: "center",
      render: (val) => {
        const nmp =
          val?.yearlyPaymentNumber > 1
            ? parseInt(12 / val?.yearlyPaymentNumber + "")
            : val?.yearlyPaymentNumber;

        return (
          <span className="text-header text-base font-normal">
            {val?.paymentBankInfo?.monthlyRentAmount * nmp} TK
          </span>
        );
      },
    },
    {
      title: "Payment Date",
      dataIndex: "createdAt",
      render: (val) => (val ? formatDate(val) : "N/A"),
    },
    {
      title: "Next Payment Date",
      dataIndex: "nextPaymentDate",
      render: (val) => (val ? formatDate(val) : "N/A"),
    },

    {
      title: "Action",
      align: "center",
      render: (val) => (
        <Link to={`/admin/dashboard/payable-users/${val?._id}`}>
          <Button type="primary">Open</Button>
        </Link>
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      getPayableUserAsync({
        params: {
          month: moment().format("MMMM").toLowerCase(),
          year: moment().year(),
        },
      })
    );
  }, [dispatch]);

  const uploadedCount = (value: number, total: number) => {
    setUploadCount(value);
    setTotalCount(total);
  };

  function reRender(val: boolean) {
    if (val) {
      // getData();
      toggle();
      navigate(`/admin/payment`);
    }
  }

  return (
    <AdminLayout title="Payable User List">
      <Subheader title="Payable User List">
        <Space className="flex-wrap" size="middle">
          <IconButton
            nextIcon={<DownloadIcon />}
            type="default"
            size="large"
            onClick={handleDownloadExcel}
            loading={excelLoading}
            // onClick={() => {
            //   handleOpen({
            //     type: "IMPORT",
            //   });
            // }}
          >
            Excel Download
          </IconButton>

          <IconButton
            nextIcon={<DownloadIcon />}
            type="primary"
            size="large"
            onClick={() => {
              handleOpen({
                type: "IMPORT",
              });
            }}
          >
            Import Excel
          </IconButton>
        </Space>
      </Subheader>

      {postLoading && uploadCount && totalCount ? (
        <Progress
          className="fixed w-full left-0 right-0 -top-[7px] z-[99999999999999999999999999999999]"
          percent={percentage(uploadCount / 2, totalCount)}
          showInfo={false}
          success={{ strokeColor: "#384DDA" }}
        />
      ) : null}

      {open?.type === "IMPORT" && (
        <ImportExcel
          open={open.open}
          handleClose={toggle}
          reRender={reRender}
          uploadedCount={uploadedCount}
        />
      )}

      <CustomTable<TableData<any>>
        loading={loading}
        total={getPayableUser?.length}
        limit={limit}
        page={page}
        handlePagination={handlePagination}
        columns={columns}
        dataList={addKeyInArray(allPayableUserData)}
        isLimitRow={true}
        showTotalPage={true}
        handleLimit={handleLimit}
        tableClass="bill_generate_table"
        // rowSelection={{
        //   type: "checkbox",
        //   onChange: (key) => setSelectedData(key),
        //   columnWidth: 45,
        // }}
      />
    </AdminLayout>
  );
}
